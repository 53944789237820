import {
  AutoComplete,
  Col,
  DatePicker,
  Row,
  Table,
  TablePaginationConfig,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import "../../AntdControlComponent/TableComponent/TableComponentCSS.css";
import "../Table.css";
import SortingButton from "../../ButtonComponents/SortingButton";
import { sorterFunc } from "../../../Helper/SortingFunction";
import moment, { Moment } from "moment";
import { Button } from "primereact/button";
import { exportExcel } from "../../../Helper/ExportExcel";
import { GetAllDynamic, updateDynamic } from "../../../Services/DynamicService";
import { useLocation } from "react-router";
import { useUserContext } from "../../../Context/UserContext";
import { IPaging } from "../../../IRequestModel/IPaginationOptionModel";
import useMasterSetting from "../../../hooks/useMasterSetting";
import { ColumnsType } from "antd/lib/table";
import {
  checkedForDataTypes,
  formatData,
  getColumnSearchProps,
} from "../../../Helper/TableHelper";
import { ColumnFilterItem, FilterValue } from "antd/lib/table/interface";
import { data } from "jquery";
const { RangePicker } = DatePicker;

type Props = {
  tableName: string;
  dataSource: any[];
  customColumns?: any[];
  setItemsCount?: (value: number) => void;
  canExport: boolean;
  setLoad?: (value: boolean) => void;
  actionBodyTemplate?: any;
  customImportFileButton?: any;
  rowClickAction?: any;
  canEdit?: any;
  toast?: any;
  loading?: boolean;
  reloadData?: (apiName: string) => void;
  VersionTempVCProps?: any;
  paginationOption?: TablePaginationConfig;
  onPageChange?: (paginationValue: IPaging) => void;
  showSumNumber?: boolean;
  setDataSource?: any
};

type ITableFilter = {
  key: string;
  type: string;
  sortType: "dec" | "asc" | null;
  value: any;
};

export type ColumnsProps = {
  key: string;
  label: string;
  type: string;
  option: any[];
  maxNumberValue?: number;
  decimal?: number;
  symbol?: string;
  dateFormat?: string;
};

const DynamicTable = ({
  tableName,
  dataSource,
  customColumns,
  canExport,
  setItemsCount,
  setLoad,
  canEdit,
  actionBodyTemplate,
  rowClickAction,
  reloadData,
  customImportFileButton,
  loading,
  paginationOption,
  onPageChange,
  showSumNumber,
}: Props) => {
  const dataKey = customColumns
    ? customColumns.map((e) => e.key)
    : Object.keys(dataSource[0] ?? {});

  const [hoverAction, setHoverAction] = useState<boolean>(false);
  const [columnsProps, setColumnsProps] = useState<ColumnsProps[]>([]);
  const [filterState, setFilterState] = useState<
    Record<string, FilterValue | null>
  >({});
  const [searchedData, setSearchedData] = useState<any[]>([]);
  const { masterSetting, getMasterSetting } = useMasterSetting();
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [showCols, setShowCols] = useState<any>();
  const [userData] = useUserContext();
  const [importLoading, setImportLoading] = useState<boolean>(false);
  const [hasArrayEmpty, setHasArrayEmpty] = useState<boolean>(false);
  useEffect(() => {
    initFunc();

    setSearchedData(dataSource);
  }, [dataSource]);

  const initFunc = async (selectedCols?: string[]) => {
    let keyTypes: ColumnsProps[] = [];
    let _selectedCols: string[] = [];
    const _masterSetting: any = await getMasterSetting();

    if (customColumns) {
      keyTypes = [...checkedForDataTypes(dataKey, dataSource)];
      if (keyTypes.length === 0) {
        console.log(keyTypes);
        keyTypes = customColumns.map((e) => {
          return {
            key: e.key,
            label: e.label,
            symbol: e.symbol,
            option: [],
            type:
              e.FieldTypeFilterDynamic === "c"
                ? "number"
                : e.FieldTypeFilterDynamic === "d"
                  ? "date"
                  : "string",
            dateFormat: _masterSetting?.FormatDate?.Value2 ?? "DD MMM YYYY",
          };
        });
      }
      keyTypes.forEach((e) => {
        const customCol = customColumns.find(
          (customCol) => e.key === customCol.key
        );

        if (customCol) {
          _selectedCols.push(e.key);
          e.label = customCol.label;
          e.symbol = customCol.symbol;
          e.decimal = customCol.Decimal != null ? Number(customCol.Decimal) : undefined;
          e.type =
            customCol.FieldTypeFilterDynamic === "c"
              ? "number"
              : customCol.FieldTypeFilterDynamic === "d"
                ? "date"
                : customCol.FieldTypeFilterStatic === "Datetime"
                  ? "datetime"
                  : customCol.FieldTypeFilterDynamic === "at"
                    ? "file"
                    : "string";

          if (e.type === "date" && _masterSetting?.FormatDate?.Value2) {
            e.dateFormat = _masterSetting?.FormatDate?.Value2;
          } else if (customCol.FieldTypeFilterDynamic === "d") {
            const symbol =
              customCol.symbol && customCol.symbol !== ""
                ? customCol.symbol
                : " ";
            e.dateFormat = "DD" + symbol + "MMM" + symbol + "YYYY";
          } else if (e.type === "datetime") {
            e.dateFormat =
              _masterSetting?.FormatDate?.Value1 || "DD MMM yyyy hh:mm:ss";
          }

          if (e.type === "number") {
            const optionValue: any = e.option.map((option) => {
              return option?.value
                ? Number(option?.value?.replaceAll(",", ""))
                : 0;
            });
            e.maxNumberValue = Math.max(optionValue);
          }
        }
      });

      if (!selectedCols) {
        selectedCols = _selectedCols.filter(
          (key: string) => !key?.toLowerCase()?.includes("id")
        );
      }
    } else {
      if (actionBodyTemplate) {
        keyTypes = [
          { key: "operation", label: "", type: "action", option: [] },
          ...checkedForDataTypes(
            dataKey,
            dataSource,
            _masterSetting?.FormatDate?.Value1?.replaceAll("d", "D")
          ),
        ];
      } else {
        keyTypes = [
          ...checkedForDataTypes(
            dataKey,
            dataSource,
            _masterSetting?.FormatDate?.Value1?.replaceAll("d", "D")
          ),
        ];
      }

      formatData(dataSource, keyTypes);

      if (!selectedCols) {
        selectedCols = dataKey.filter(
          (key: string) => !key?.toLowerCase()?.includes("id")
        );
      }
    }

    setColumnsProps(
      keyTypes?.filter(
        (e) =>
          e.key === "operation" ||
          (selectedCols?.includes(e.key) &&
            !["isEditing", "isPublishVersion"].includes(e.key))
      )
    );

    setShowCols(selectedCols ?? []);
  };

  const sorterDate = (a: Moment | null, b: Moment | null) => {
    if (a === null && b === null) return 0;
    if (a === null) return -1;
    if (b === null) return 1;

    if (!a.isValid() && !b.isValid()) return 0;
    if (!a.isValid()) return -1;
    if (!b.isValid()) return 1;

    if (a.isBefore(b)) return -1;
    if (a.isAfter(b)) return 1;
    return 0;
  };

  const renderColumnBody = (value: any, colProp: ColumnsProps, record: any) => {


    switch (colProp.type) {
      case "number":
        if (colProp.symbol) {

          return value
            ? colProp.decimal === undefined ? value.toString() : Number(value.toString()?.replaceAll(",", "")).toLocaleString(
              undefined,
              { minimumFractionDigits: colProp.decimal }
            ) + colProp.symbol
            : "";
        } else {


          return value
            ? colProp.decimal === undefined ? value.toString() : Number(value.toString()?.replaceAll(",", "")).toLocaleString(
              undefined,
              { minimumFractionDigits: colProp.decimal }
            )
            : "";
        }

      case "date":
        const date = value as Moment;
        try {
          return date?.isValid() ? date.format(colProp.dateFormat) : "";
        } catch (error) {
          return date;
        }
      case "datetime":
        const datetime = value as Moment;
        try {
          return datetime?.isValid()
            ? datetime.format(colProp.dateFormat?.replaceAll("d", "D"))
            : "";
        } catch (error) {
          return datetime;
        }

      case "file":
        return value;

      default:
        if (colProp.key === "TemplateName") {
          return (
            <>
              {value}
              {record.isPublishVersion === "true" && (
                <Tag color="#007bff" style={{ borderRadius: "6px" }}>
                  Publish
                </Tag>
              )}
              {record.isEditing === "true" && (
                <Tag color="#dc3545" style={{ borderRadius: "6px" }}>
                  Editing
                </Tag>
              )}
            </>
          );
        } else if (colProp.key === "AmountFrom_AmountTo") {
          const dd = value?.split(":");
          let htmlBody: any[] = [];
          dd.forEach((data: any, idx: number) => {
            if (idx < 4) {
              htmlBody.push(<p>{data}</p>);
            } else {
              htmlBody.push(<p>.......</p>);
            }
          });
          return <>{htmlBody}</>;
        } else {
          return value?.toString();
        }
    }
  };

  const onExportClick = async () => {
    setExportLoading(true);
    const columnHeader = columnsProps.map((col) => ({
      label: col.label,
      key: col.key,
    }));
    await exportExcel(
      columnHeader,
      tableName,
      reconStructionForExport(),
      setExportLoading
    );
    setExportLoading(false);
  };
  function reconStructionForExport() {
    let dataExport: any[] = [];
    try {
      searchedData.forEach((currentData) => {
        let exportRow: any = {};
        columnsProps.forEach((col: ColumnsProps) => {
          if (!showCols.includes(col.key)) return;
          if (!currentData[col.key]) return "";
          if (col.type === "date" || col.type === "datetime") {
            exportRow[col.key] = currentData[col.key].format(col.dateFormat);
          } else if (col.type === "number") {
            exportRow[col.key] = Number(currentData[col.key]);
          } else {
            exportRow[col.key] = currentData[col.key];
          }
        });
        if (JSON.stringify(exportRow) !== "{}") {
          dataExport.push(exportRow);
        }
      });
    } catch (error) {
      console.error("table=>error", error);
    }
    return dataExport;
  }
  const [optionPosition, setOptionPosition] = useState<any[]>([]);
  const importExcel = async (e: any) => {
    const file = e.target.files[0];

    try {
      import("xlsx").then((xlsx) => {
        const reader = new FileReader();

        try {
          reader.onload = async (e: any) => {
            const wb = xlsx.read(e.target.result, { type: "array" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
            const cols: any = data[0];
            data.shift();
            let _importedData = data.map((d: any) => {
              return cols.reduce((obj: any, c: any, i: any) => {
                obj[c] = d[i];
                return obj;
              }, {});
            });

            if (_importedData.length !== 0) {
              const data = optionPosition.length
                ? optionPosition
                : await GetAllDynamic(
                  "PositionLevel/GetAll?IsActive=true",
                  undefined
                );
              setOptionPosition(data);
              let res = null;
              for (let i = 0; i < _importedData.length; i++) {
                const element = _importedData[i];
                var apiNamereplace = tableName?.replace("/GetAll", "");
                if (
                  !element[apiNamereplace + "Id"] ||
                  element[apiNamereplace + "Id"] === ""
                ) {
                  element.CreatedBy = userData.EmployeeId.toString();
                  element.ModifiedBy = userData.EmployeeId.toString();
                } else {
                  element.ModifiedBy = userData.EmployeeId.toString();
                }
                const { PositionLevelId, PositionId } =
                  Array.isArray(data) &&
                  data.find((pos) => {
                    return pos?.NameEn === element?.PositionLevelNameEn;
                  });
                element.PositionId = PositionId;
                element.AccountId = userData.EmployeeId.toString();
                element.PositionLevelId = PositionLevelId;
                setImportLoading(true);

                res = await updateDynamic(apiNamereplace, element);
              }

              if (res.result === "success") {
                if (reloadData) {
                  reloadData(tableName);
                }
              }
              setImportLoading(false);
            }
          };

          reader.readAsArrayBuffer(file);
        } catch (error) {
          setImportLoading(false);
        }
      });
    } catch (error) {
      setImportLoading(false);
    }
    e.target.value = null;
  };

  const columns: ColumnsType<any> = columnsProps
    .filter((e) => showCols?.includes(e.key) || e.key === "operation")
    ?.map((colProp) => {
      const key = colProp.key;

      if (canEdit && key === "operation") {
        return {
          key: "operation",
          align: "center",
          fixed: "left",
          width: 25,
          render: (_: any, record: any) => {
            if (actionBodyTemplate) {
              return (
                <div
                  onMouseOver={(e: any) => setHoverAction(true)}
                  onMouseLeave={(e: any) => setHoverAction(false)}
                >
                  {actionBodyTemplate(record)}
                </div>
              );
            }
          },
        };
      }

      return {
        title: <span className="headtext-form">{colProp.label}</span>,
        dataIndex: key,
        key: key,
        align: colProp.type === "number" ? "right" : "left",
        width: 250,
        render: (value: any, record: any, index: number) =>
          renderColumnBody(value, colProp, record),
        sorter: (a: any, b: any) => {
          return colProp.type === "date" || colProp.type === "datetime"
            ? sorterDate(a[key], b[key])
            : a[key]?.toString().localeCompare(b[key]?.toString());
        },
        ...getColumnSearchProps(colProp, dataSource, filterState, columnsProps),
      };
    });

  useEffect(() => {
    setItemsCount && setItemsCount(showCols?.length ? dataSource.length : 0);
  }, [dataSource, hasArrayEmpty, showCols?.length]);

  return (
    <div className="dynamictable-container">
      <Row className="dynamictable-header" gutter={[0, 5]}>
        <Col xs={24} sm={24} md={10} lg={8} xl={8}>
          <MultiSelect
            value={showCols}
            options={
              customColumns ??
              dataKey.filter((e) => !e.toLowerCase().endsWith("id"))
            }
            onChange={(e) => {
              setShowCols(e.value);
            }}
            showClear
            placeholder="Select columns"
            style={{ width: "50%" }}
            optionLabel={customColumns ? "label" : undefined}
            optionValue={customColumns ? "key" : undefined}
          />
        </Col>
        {canExport && (
          <Col>
            <Button
              label="Export to excel"
              icon="pi pi-file-excel"
              data-pr-tooltip="XLS"
              loading={exportLoading}
              onClick={onExportClick}
              style={{
                height: "38px",
                background: " #28a745",
                border: "1px solid #28a745",
                color: "#ffffff",
                fontSize: "1em",
                borderRadius: "6px",
                margin: " 0px 11px 0px 0px",
                justifyContent: "center",
              }}
            />
            {!location.pathname.includes("DynamicReport") &&
              !location.search?.includes("DelegateList") &&
              !location.search?.includes("TimeStamp") && (
                <>
                  {customImportFileButton ? (
                    customImportFileButton
                  ) : (
                    <>
                      <input
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={importExcel}
                        style={{ display: "none" }}
                        id="file"
                      />
                      <label
                        htmlFor="file"
                        style={{
                          height: "38px",
                          background: " #28a745",
                          border: "1px solid #28a745",
                          color: "#ffffff",
                          fontSize: "1em",
                          borderRadius: "6px",
                          padding: "0px 5px",
                        }}
                        className="import"
                      >
                        <span className="import-span">
                          <i
                            className="pi pi-file-excel"
                            style={{ margin: "3px 5px 5px 5px" }}
                          ></i>
                          Import from excel
                        </span>
                      </label>
                    </>
                  )}
                </>
              )}
          </Col>
        )}
      </Row>
      <Row>
        <Table
          className="wolf-table"
          size="middle"
          loading={loading}
          dataSource={showCols?.length ? dataSource : []}
          rowKey={"rowIdx"}
          onChange={(pagination, filters, sorter, extra) => {
            const hasArrayEmpty = Object.keys(filters).some(
              (key) =>
                (Array.isArray(filters[key]) && filters[key].length === 0) ||
                filters[key] === null
            );

            setHasArrayEmpty(hasArrayEmpty);
            if (extra.action === "filter") {
              const updatedFilters = { ...filterState, ...filters };
              setFilterState(updatedFilters);
            }

            if (setItemsCount) setItemsCount(extra.currentDataSource.length);
            setSearchedData(extra.currentDataSource);
          }}
          columns={columns}
          rowClassName={`wolf-table-row ${rowClickAction && "hover"}`}
          scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (rowClickAction && !hoverAction)
                  rowClickAction(record, rowIndex, event);
              },
            };
          }}
          summary={(data: any) => {
            if (!showSumNumber) {
              return;
            }
            const columnSums: { [key: string]: number } = {};

            columnsProps.forEach((column) => {
              if (column.type === "number") {
                columnSums[column.key] = data.reduce(
                  (sum: number, row: any) =>
                    sum + (Number(row[column.key]) || 0),
                  0
                );
              }
            });

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <strong>Totals</strong>
                  </Table.Summary.Cell>
                  {columnsProps.map((column, index) => {
                    if (index > 0) {
                      return (
                        <Table.Summary.Cell
                          align="right"
                          index={index}
                          key={column.key}
                        >
                          {column.type === "number"
                            ? new Intl.NumberFormat("th-TH", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(columnSums[column.key])
                            : null}
                        </Table.Summary.Cell>
                      );
                    }
                    return null;
                  })}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Row>
    </div>
  );
};

export default DynamicTable;
