import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Cookies from "universal-cookie";
import { useLocation, useHistory } from "react-router-dom";
import { useSessionContext } from "../Context/AuthContext";
import { LoginScreen } from "../screens/LoginScreen/LoginScreen";
import { LoginScreenAzure } from "../screens/LoginScreen/LoginScreenAzure";
import { DynamicReport } from "../screens/Report/DynamicReport";
import ReportScreen from "../screens/Report/ReportScreen";
import WorkListScreen from "../screens/WorkList/WorkListScreen";
import PrivateRoute, { ProtectedRouteProps } from "./PrivateRoute";
import { ErrorPageComponent } from "../components/ErrorPageComponent/ErrorPageComponent";
import {
  CheckAppSetting,
  CheckCanDownloadPdf,
  CheckCanEditProfile,
} from "../Services/AppSettingService";
import TemplateDetailScreen from "../screens/TemplateDetailScreen/TemplateDetailScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen/ForgotPasswordScreen";
import DelegateScreen from "../screens/DelegateScreen/DelegateScreen";
import EmailTemplateDetailScreen from "../screens/SettingsSpecScreen/EmailTemplateDetailScreen";
import SimLineApproveScreen from "../screens/SettingScreen/SimLineApproveScreen/SimLineApproveScreen";
import DashboardScreen from "../screens/DashboardScreen/DashboardScreen";
import { LogApi } from "../screens/LogApi/LogApi";
import EmailTemplateListScreen from "../screens/SettingsSpecScreen/EmailTemplateListScreen/EmailTemplateListScreen";
import { PreviewTemplateScreen } from "../screens/TemplateDetailScreen/PreviewTemplateScreen";
import { LoginGoogle } from "../screens/LoginScreen/LoginGoogle";
import UnAurthorization from "../screens/UnAuthorizePage/UnAurthorization";
import { useMsal } from "@azure/msal-react";

import RegisterWolfScreen from "../screens/RegisterWolfScreen/RegisterWolfScreen";
import "./switchRoute.css";
import RequestScreenFix from "../screens/Request/RequestScreenFix";
import MasterDatascreenFixed from "../screens/SettingScreen/MasterDataScreen/MasterDatascreenFixed";
import ReportScreenFix from "../screens/Report/ReportScreenFix";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import TimeStampScreen from "../screens/TimeStamp/TimeStampScreen";
import PreviewAttachmentScreen from "../screens/PreviewAttachmentScreen/PreviewAttachmentScreen";
import RequestActionMemo from "../screens/RequestActionMemo/RequestActionMemo";
import { LoginConfiguration } from "../Services/ConfigurationService";
import { GetMasterDataBrowserAgent } from "../Services/MasterDataService";
import RejectBrowser from "../screens/RejectBrowerScreen/RejectBrowser";
import { LoginSSUP } from "../screens/LoginScreen/LoginSSUP";
import { LoginScreenOpenId } from "../screens/LoginScreen/LoginScreenOpenId";
import BudgetSummary from "../screens/BudgetScreen/BudgetTrackingScreen";
import { ProcurementTracking } from "../screens/Procurement/ProcurementTrackingScreen";
import PaymentReport from "../module/frasers/screens/BudgetReportScreen/PaymentReport";
import { v4 as uuidv4 } from "uuid";
import { browserDetector } from "../Helper/DeviceDetect";
interface Props {
  responeConfig: any;
  isLoadPackage: boolean;
  setIsLoadPackage: any;
}

const Routes = (props: Props) => {
  const [responeConfig, setResponeConfig] = useState<any>();
  const [loginScreen, setLoginScreen] = useState<any>();
  const cookies = new Cookies();
  const history = useHistory();
  const [sessionContext, updateSessionContext] = useSessionContext();
  const location = useLocation();
  const [isLoadSuccess, setIsLoadSuccess] = useState<boolean>(false);
  const { search, pathname } = useLocation();
  const [uid, setUid] = useState<string | null>(null);

  useEffect(() => {
    const generatedUid = uuidv4();
    setUid(generatedUid);
  }, []);

  useEffect(() => {
    if (uid) {
      const sanitizedUid = uid.replace(/-/g, "").toUpperCase();
      const checkUrl = pathname.includes("/Default");
      if (checkUrl) {
        history.replace(`${pathname}?WFID=${sanitizedUid}`);
      } else {
        return;
      }
    }
  }, [uid, search, pathname, history]);

  useEffect(() => {
    const guidVerify = cookies.get("GuidVerify");
    const userData = window.localStorage.getItem("userData");
    const loadApp = async () => {
      try {
        const data = await fetchApp();

        if (!guidVerify || !data || userData == null) {
          cookies.remove("GuidVerify");
          updateSessionContext({ ...sessionContext, isAuthenticated: false });
          setIsLoadSuccess(false);
        } else {
          const browserConfig = await GetMasterDataBrowserAgent();
          const browser = browserDetector();
          const isAllowBrowser =
            browserConfig == null ||
            browserConfig.browsers.length <= 0 ||
            browserConfig.browsers.some(
              (x: any) => x.toLowerCase() === browser.toLowerCase()
            );

          if (!isAllowBrowser) {
            localStorage.setItem(
              "mstDatabrowserConfig",
              JSON.stringify(browserConfig)
            );
            if (location.pathname !== "/Reject") {
              setRedirectPath("/Reject");
              history.push("/Reject");
            }
            setIsLoadSuccess(false);
          } else {
            setIsLoadSuccess(true); // Application successfully loaded
          }
        }
      } catch (error) {
        console.error("Error loading app:", error);
        setIsLoadSuccess(false); // Set false if any error occurs
      }
    };
    loadApp();
  }, [location]);
 
  const setRedirectPath = (path: string) => {
    console.log(path);

    updateSessionContext({ ...sessionContext, redirectPath: path });
  };
  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: !!sessionContext.isAuthenticated,
    authenticationPath: "/login",
    redirectPath: sessionContext.redirectPath,
    setRedirectPath: setRedirectPath,
  };
  const fetchApp = async () => {
    const responseConfig = await LoginConfiguration();
    const response = await CheckAppSetting();
    const canEditProfile = await CheckCanEditProfile();
    const canDownLoadPdf = await CheckCanDownloadPdf();
    setResponeConfig(responseConfig);
    window.localStorage.setItem(
      "isWolf",
      props.responeConfig?.type === "LoginWolf" ? "true" : "false"
    );
    if (props.responeConfig?.type == "LoginOpenId") {
      setLoginScreen(
        <LoginScreenOpenId
          {...{ setIsLoadPackage: props.setIsLoadPackage }}
          PathLogo={props.responeConfig.pathLogoLogin}
          PathCarousel={props.responeConfig.pathCarousel}
        />
      );
    } else if (props.responeConfig?.type === "LoginAzure") {
      setLoginScreen(
        <LoginScreenAzure
          pathLogo={props.responeConfig.pathLogoLogin}
          pathCarousel={props.responeConfig.pathCarousel}
        />
      );
    } else if (props.responeConfig?.type === "LoginWolf") {
      setLoginScreen(
        <LoginScreen
          {...{ setIsLoadPackage: props.setIsLoadPackage }}
          PathLogo={props.responeConfig.pathLogoLogin}
          IsMulti={props.responeConfig.isMulti}
          PathCarousel={props.responeConfig.pathCarousel}
          HidePassAndRegister={props.responeConfig.hidePassAndRegister}
        />
      );
    } else if (props.responeConfig?.type === "LoginGoogle") {
      setLoginScreen(<LoginGoogle />);
    } else if (props.responeConfig?.type === "LoginSSUP") {
      setLoginScreen(
        <LoginSSUP
          pathLogo={props.responeConfig.pathLogoLogin}
          pathCarousel={props.responeConfig.pathCarousel}
        />
      );
    }

    window.localStorage.setItem(
      "canEditProfile",
      JSON.stringify(canEditProfile)
    );
    window.localStorage.setItem(
      "canDownLoadPdf",
      JSON.stringify(canDownLoadPdf)
    );
    return response;
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={location.pathname}
        classNames="fade"
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
      >
        <Switch location={location}>
          <Route path={"/Reject"} component={RejectBrowser} />
          <Route path="/login">{loginScreen}</Route>
          <Route path="/callback">{loginScreen}</Route>
          <Route path="/LoginAzure">
            <LoginScreenAzure
              pathLogo={props.responeConfig.pathLogoLogin}
              pathCarousel={props.responeConfig.pathCarousel}
            />
          </Route>
          <Route path="/Verify">
            <ForgotPasswordScreen
              PathLogo={props.responeConfig.pathLogoLogin}
            />
          </Route>
          <Route path={"/RegisterWolfScreen"}>
            <RegisterWolfScreen
              PathLogo={props.responeConfig.pathLogoLogin}
              responeConfig={responeConfig}
            />
          </Route>
          <PrivateRoute
            {...defaultProtectedRouteProps}
            path="/RequestActionMemo"
          >
            <RequestActionMemo PathLogo={props.responeConfig.pathLogoLogin} />
          </PrivateRoute>
          <PrivateRoute
            {...defaultProtectedRouteProps}
            path="/previewAttachment"
          >
            <PreviewAttachmentScreen />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path="/request">
            <RequestScreenFix />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path="/TimeStamp">
            <TimeStampScreen />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path="/Default">
            <WorkListScreen responeConfig={responeConfig} />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path="/DynamicReport">
            <ReportScreenFix />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path="/TemplateDetail">
            <TemplateDetailScreen responeConfig={responeConfig} />
          </PrivateRoute>
          <PrivateRoute
            {...defaultProtectedRouteProps}
            path="/EmailTemplateList"
          >
            <EmailTemplateListScreen />
          </PrivateRoute>
          <PrivateRoute
            {...defaultProtectedRouteProps}
            path="/EmailTemplateDetail"
          >
            <EmailTemplateDetailScreen />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path="/request/copy">
            <ReportScreen responeConfig={responeConfig} />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path="/PreviewTemplate">
            <PreviewTemplateScreen />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path={"/Delegate"}>
            <DelegateScreen responeConfig={responeConfig} />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path={"/Settings"}>
            <MasterDatascreenFixed />
          </PrivateRoute>
          <PrivateRoute
            {...defaultProtectedRouteProps}
            path={"/SimLineApprove"}
          >
            <SimLineApproveScreen responeConfig={responeConfig} />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path={"/Dashboard"}>
            <DashboardScreen responeConfig={responeConfig} />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path={"/UnAuthorize"}>
            <UnAurthorization />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path={"/Logapi"}>
            <LogApi />
          </PrivateRoute>
          <PrivateRoute {...defaultProtectedRouteProps} path={"/BudgetSummary"}>
            <BudgetSummary />
          </PrivateRoute>
          <PrivateRoute
            {...defaultProtectedRouteProps}
            path={"/ProcurementTracking"}
          >
            <ProcurementTracking />
          </PrivateRoute>
          <PrivateRoute
            {...defaultProtectedRouteProps}
            path={"/Frasers/PaymentReport"}
          >
            <PaymentReport />
          </PrivateRoute>

          <PrivateRoute {...defaultProtectedRouteProps} path="*">
            {isLoadSuccess && <ErrorPageComponent />}
          </PrivateRoute>

          <Route component={DynamicReport} />
        </Switch>
      </CSSTransition>
    </SwitchTransition>
  );
};
export default Routes;
